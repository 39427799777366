<template>
  <div class="videos">
    <div id="remoteTrack" v-on:click="showHideButton">
      <div v-if="waiting" class="waiting">
        <div class="bg"></div>
        <div class="watch-face">
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
        </div>
        <div v-if="isDoctor">
          <h3>Waiting for patient</h3>
        </div>
        <div v-else>
          <h3>Waiting for your doctor</h3>
        </div>
      </div>
    </div>
    <div id="localTrack" :class="bottomPadding"></div>
    <div id="buttons" :class="buttonClass">
      <div id="endCall">
        <EndCall />
      </div>
      <div id="mute" :class="redMute">
        <UnMute v-if="mute" />
        <Mute v-else />
      </div>
      <div id="videoOff" :class="redVideo">
        <VideoOn v-if="videoTrackOff" />
        <VideoOff v-else />
      </div>
      <div id="switchCamera" v-if="hasManyCameras">
        <SwitchCamera />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import Twilio, {
  createLocalVideoTrack,
  RemoteParticipant,
  createLocalTracks,
  TwilioError,
} from "twilio-video";
import EndCall from "./icons/EndCall.vue";
import Mute from "./icons/Mute.vue";
import UnMute from "./icons/UnMute.vue";
import SwitchCamera from "./icons/SwitchCamera.vue";
import VideoOn from "./icons/VideoOn.vue";
import VideoOff from "./icons/VideoOff.vue";
import router from "../router"

export default {
  name: "Video",
  data() {
    return {
      remoteTrack: "",
      localParticipant: "",
      previewTracks: "",
      identity: "",
      waiting: true,
      facingMode: "environment", // "user", "environment"
      accessToken: "",
      room: "",
      mute: false,
      videoTrackOff: false,
      buttonClass: "compress",
      bottomPadding: "bottom-padding ",
      redMute: "",
      redVideo: "",
      hasManyCameras: false,
      isDoctor: false
    };
  },
  components: {
    EndCall,
    Mute,
    UnMute,
    SwitchCamera,
    VideoOn,
    VideoOff,
  },
  created() {
    window.addEventListener("beforeunload", () => {
      navigator.mediaDevices.getUserMedia().then((stream) => {
        stream.getTracks().forEach((track) => track.stop())
      })
    });
  },

  mounted() {
    console.log("joing room")
    this.createChat();

    this.checkCameras();
    setTimeout(() => {
      this.showHideButton();
    }, 10000);
  },
  methods: {
    isMobile() {
      return screen.width < 700
    },
    checkCameras() {
      const VueThis = this
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        if (devices.length == 0) {
          alert('No camera found. Please allow browser to the access camera or use a different device')
        }
        if (devices.length > 1) {
          VueThis.hasManyCameras = true
        }
      });
    },
    showHideButton() {
      if (!this.isMobile()) {
        return // hide only in mobiles
      }

      if (this.buttonClass == "compress") {
        this.buttonClass = "";
        setTimeout(() => {
          this.buttonClass = "show";
          this.bottomPadding = "bottom-padding";
          setTimeout(() => {
            this.showHideButton();
          }, 10000);
        }, 200);
      } else {
        this.buttonClass = "";
        setTimeout(() => {
          this.buttonClass = "compress";
          this.bottomPadding = "";
        }, 800);
      }
    },
    participantConnected(participant) {
      let remoteContainer = document.getElementById("remoteTrack");

      const VueThis = this;

      participant.on("trackSubscribed", (track) => {
        VueThis.waiting = false;
        VueThis.trackSubscribed(remoteContainer, track);
      });

      participant.on("trackUnsubscribed", (track) => {
        VueThis.trackUnsubscribed(track);
      });

      participant.tracks.forEach((publication) => {
        VueThis.waiting = false;
        if (publication.isSubscribed) {
          this.trackSubscribed(remoteContainer, publication.track);
        }
      });
    },

    participantDisconnected(participant) {
      console.log(participant);
    },

    trackSubscribed(div, track) {
      this.waiting = false;
      if (track.kind != "data") {
        div.appendChild(track.attach());
      }
    },

    trackUnsubscribed(track) {
      if (track.kind != "data") {
        track.detach().forEach((element) => element.remove());
      }
    },
    SwitchCamera() {
      if (this.facingMode == "environment") {
        this.facingMode = "user";
      } else {
        this.facingMode = "environment";
      }
    },
    // Create a new chat
    createChat() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      this.room = params.room;
      this.isDoctor = params.isDoctor || false;
      this.accessToken = params.token
      this.joinConference();
    },

    joinConference() {
      const VueThis = this;

      createLocalTracks({
        audio: true,
        facingMode: this.facingMode,
        video: {
          width: 640,
          facingMode: this.facingMode
        },
      }).then((localTacks) => {
        Twilio.connect(VueThis.accessToken, {
          name: this.room,
          tracks: localTacks,
        }).then((room) => {
          VueThis.localParticipant = room.localParticipant;
          VueThis.buttonClass = "show";
          VueThis.bottomPadding = "bottom-padding";

          //attach the user local track
          room.localParticipant.videoTracks.forEach((publication) => {
            let localMediaContainer = document.getElementById("localTrack");
            localMediaContainer.append(publication.track.attach());
          })

          // get the tracks of remote participants
          room.participants.forEach((participantConnected) => {
            VueThis.participantConnected(participantConnected);
          });

          room.on("participantConnected", function (participant) {
            VueThis.participantConnected(participant);
          });

          room.on("participantDisconnected", function (participant) {
            VueThis.participantDisconnected(participant);
          });

          room.once("disconnected", (error) =>
            room.participants.forEach(function (participant) {
              VueThis.participantDisconnected(participant);
            })
          );

          let muteButton = document.getElementById("mute");
          muteButton.addEventListener("click", (event) => {
            event.stopPropagation()
            VueThis.mute = !VueThis.mute;
            if (VueThis.mute) {
              VueThis.redMute = "red-mute"
              room.localParticipant.audioTracks.forEach((publication) => {
                publication.track.disable();
              })
            } else {
              VueThis.redMute = ""
              room.localParticipant.audioTracks.forEach((publication) => {
                publication.track.enable()
              })
            }

          });

          document.getElementById("videoOff").addEventListener('click', (event) => {
            event.stopPropagation();
            VueThis.videoTrackOff = !VueThis.videoTrackOff
            if (VueThis.videoTrackOff) {
              VueThis.redVideo = "red-video"
              room.localParticipant.videoTracks.forEach((publication) => {
                publication.track.disable();
              })
            } else {
              VueThis.redVideo = ""
              room.localParticipant.videoTracks.forEach((publication) => {
                publication.track.enable();
              })
            }
          })

        }).catch((error) => {
          if (error.name == 'TwilioError' && error.message == 'Access Token expired or expiration date invalid') {
            localTacks.forEach((track) => {
              track.stop()
            })
            router.push({ path: '/about' })
          }

        });
      });
    },
  },
};
</script>

<style>
#remoteTrack {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: rgb(46, 46, 46);
}

.remote_video_container {
  left: 0;
  margin: 0;
  border: 1px solid rgb(124, 129, 124);
}

#remoteTrack video {
  margin: auto;
  height: 100%;
}

#localTrack {
  transition: all 0.75s ease;
}

#localTrack video,
#localTrack {
  border: 1px solid white;
  margin: 0px;
  max-width: 20vw !important;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.bottom-padding {
  margin-bottom: 65px !important;
}

.waiting {
  color: #fff;
  margin: auto;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  #localTrack video {
    max-width: 45% !important;
  }
}

/* bottom button */
#buttons {
  display: flex;
  position: absolute;
  bottom: 5px;
  width: 100%;
  height: 84px;
  opacity: 0;
  transition: all 0.75s ease;
}

#buttons.show {
  opacity: 1;
  height: 84px;
}

#buttons.compress {
  height: 1 !important;
}

#endCall {
  background-color: red;
  border-radius: 37px;
  padding: 10px 11px 3px 4px;
  margin: 10px;
}

#mute {
  background-color: #ffbf00;
  margin-bottom: 10px;
  margin-top: 30px;
  margin-right: 10px;
  margin-left: auto;
  padding: 10px;
  padding-bottom: 7px;
  border-radius: 30px;
  transition: all 0.5s ease;
}

#switchCamera,
#videoOff {
  background-color: #ffbf00;
  margin-bottom: 10px;
  margin-top: 30px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
  padding-bottom: 7px;
  border-radius: 30px;
  transition: all 0.5s ease;
}

.red-mute,
.red-video {
  background-color: red !important;
}

/* background static */
.bg {
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url("http://assets.iceable.com/img/noise-transparent.png") repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation 0.2s infinite;
  opacity: 0.9;
  visibility: visible;
}


@keyframes bg-animation {
  0% {
    transform: translate(0, 0);
  }

  10% {
    transform: translate(-5%, -5%);
  }

  20% {
    transform: translate(-10%, 5%);
  }

  30% {
    transform: translate(5%, -10%);
  }

  40% {
    transform: translate(-5%, 15%);
  }

  50% {
    transform: translate(-10%, 5%);
  }

  60% {
    transform: translate(15%, 0);
  }

  70% {
    transform: translate(0, 10%);
  }

  80% {
    transform: translate(-15%, 0);
  }

  90% {
    transform: translate(10%, 5%);
  }

  100% {
    transform: translate(5%, 0);
  }
}

/* breathing */
.watch-face {
  height: 125px;
  width: 125px;
  animation: pulse 8s cubic-bezier(0.5, 0, 0.5, 1) alternate infinite;
  margin: auto;
  margin-bottom: 70px;
}

.circle {
  height: 125px;
  width: 125px;
  border-radius: 50%;
  position: absolute;
  mix-blend-mode: screen;
  transform: translate(0, 0);
  animation: center 6s infinite;
}

.circle:nth-child(odd) {
  background: #61bea2;
}

.circle:nth-child(even) {
  background: #529ca0;
}

.circle:nth-child(1) {
  animation: circle-1 4s ease alternate infinite;
}

.circle:nth-child(2) {
  animation: circle-2 4s ease alternate infinite;
}

.circle:nth-child(3) {
  animation: circle-3 4s ease alternate infinite;
}

.circle:nth-child(4) {
  animation: circle-4 4s ease alternate infinite;
}

.circle:nth-child(5) {
  animation: circle-5 4s ease alternate infinite;
}

.circle:nth-child(6) {
  animation: circle-6 4s ease alternate infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.15) rotate(180deg);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes circle-1 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-35px, -50px);
  }
}

@keyframes circle-2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(35px, 50px);
  }
}

@keyframes circle-3 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-60px, 0);
  }
}

@keyframes circle-4 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(60px, 0);
  }
}

@keyframes circle-5 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-35px, 50px);
  }
}

@keyframes circle-6 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(35px, -50px);
  }
}
</style>
