<template>
 <div class="video-container">
       <Video />
 </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Video from '@/components/Video.vue';// @ is an alias to /src

@Options({
  components: {
    Video,
  },
})
export default class Home extends Vue {}
</script>
<style scoped>

</style>