<template>
<router-view/>

</template>

<script>
// import components
// import Video from './components/Video'

export default {
 name: 'App',
//  components: {
//    Video,
//  },

 }
</script>

<style>
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
 #app {
   font-family: 'Avenir', Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   background: white;
   height: 100%;
   width: 100%;
 }
</style>